export const PRICING_PLAN_AMOUNTS: Record<string, Record<string, null | string>> = {
  gold: {
    monthly: null,
    quarterly: null,
  },
  pro: {
    monthly: '19.00',
    quarterly: '16.33',
  },
}

export const LIMITED_FEATURES = [
  'Limited Resumes',
  'Limited Cover Letters',
  'Limited Job Applications Tracking',
  'Limited Mock Interviews',
  'Limited Canyon AI',
]

export const UNLIMITED_FEATURES = [
  'Unlimited Resumes',
  'Unlimited Resume Optimizations',
  'Unlimited Cover Letters',
  'Unlimited Job Applications Tracking',
  'Unlimited Mock Interviews',
  'Unlimited Canyon AI',
]

export const GOLD_FEATURES = [
  'All Pro Features',
  'Guided Application Tasks at Every Step',
  'Real-Time Company Enrichment',
  'Personalized Tone of Voice',
  'Personalized Contact Recommendations',
  'Tailored Networking Messages',
]
